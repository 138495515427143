.video {
  position: relative;
  color: white;
  text-align: center;
  margin-top: 110px;
  border-radius: 30px;
}
.video-box {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.content {
  position: relative;
  color: white;
  text-align: center;
  margin-top: 0px;
}


.slide-in-container {
  overflow: hidden;
}

.slide-in-image {
  position: relative;
  right: -100%; /* Initially hide the image to the left */
  transition: right 1s ease-out; /* Animate the 'left' property */
  width: 100%;
  height:auto;
  margin: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;
  border-radius: 30px;
  margin-top:"5px"
}

.slide-in-image:hover {
  transform: scale(1.1); 

}
.slide-in {
  right: 0; /* Move the image into view */
}

.slide-image {
  transition: transform 0.3s ease;
  cursor: pointer;
  border-radius: 30px;

}

.slide-image:hover {
  transform: scale(1.1); 

}






.screen2{
  position: relative;
  color: white;
  text-align: center;
  margin-top: 100px;

}
.screen3{
  position: relative;
  text-align: center;
  margin-top: 100px;
  justify-content: center;
  color: white;
}
.screen5{
  position: relative;
  text-align: left;
  margin-top: 100px;
  justify-content: left;
  color: white;
}
.screen6{
  position: relative;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
  justify-content: center;
  color: white;
}
.footer{
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  color: white;
}
.screen6{
  position: relative;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
  justify-content: center;
  color: white;
}

#tsparticles {
  width: 100%;
}

.img-adjust {
  width: 100%;
  height: 295px;
  margin: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;
  border-radius: 30px;
}

.img-adjust:hover {
  transform: scale(1.1); 

}

@media (max-width: 767px) {
  .img-adjust{
    width: 100%;
    height: auto;
    margin: 10px;
    
  }
  .slide-in-image{
    width: 98%;
    height: auto;

  }
}