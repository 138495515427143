
.navbar{
background-color: rgb(35, 35, 35) !important;
height: 105px;
}
.container-fluid{
    background-color: rgb(35, 35, 35) !important;
}

.navbar-brand{
    height: auto;
    width: 140px;
    padding: 3px;
    margin-left: 10px;
}

.nav-item{
    padding: 10px;

}

.drpodown{
    background-color: black;
}