.jobpost {
    position: relative;
    color: white;
    margin-top: 100px;
  }
  .jobget {
    position: relative;
    color: white;
    text-align: center;
    margin-top: 120px;
  }
  .Filterjobcountry {
    position: relative;
    color: white;
    text-align: center;
    margin-top: 110px;
  }
  .Filterjobs {
    position: relative;
    color: white;
    text-align: center;
    margin-top: 110px;
  }

  .apply-any{
    position: relative;
    color: white;
    text-align: center;
    margin-top: 140px;
}

  .jobpost-img {
    padding: 5px;
    border-radius: 20px;
  }
  .jobpost-img {
    padding: 5px;
    width: 300px;
    height: 250px;
    border-radius: 20px;
  }



  @media (max-width: 767px) {
    .jobpost {
      margin-top: 100px; 
      padding: 10px; 
      font-size: 14px; 
    }
    .jobget{
      margin-top: 110px; 
      padding: 10px; 
      font-size: 14px;
    }
  
    .jobpost-img{
      width: 200px; 
      height: auto;
      padding: 5px;
      border-radius: 20px;
    }
    .jobpost-img {
      width: 200px; 
      height: 230px;
      padding: 5px;
      border-radius: 20px;
    }
    .Filterjobcountry {
      position: relative;
      color: white;
      text-align: center;
      margin-top: 110px;
    }
    .Filterjobs {
      position: relative;
      color: white;
      text-align: center;
      margin-top: 110px;
    }
  }