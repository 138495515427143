.Contactus{
    position: relative;
    color: white;
    text-align: center;
    margin-top: 130px;
}

@media (max-width: 767px) {
    .Contactus{
        position: relative;
        color: white;
        text-align: center;
        margin-top: 120px;
    }
}