.login {
    position: relative;
    color: white;
    text-align: center;
    margin-top: 10px;
  }
  
  @media (max-width: 767px) {
    .login {
      position: relative;
      color: white;
      text-align: center;
      margin-top: 150px;
    }
  }